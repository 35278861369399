// import LoaderIcon from '@/components/Common/LoaderIcon';
import FlatList from '@/components/FlatList';
import {
  filtersCounter,
  getFilteredItemsOptions,
  resetFilterItems,
  saveFilteredOptions,
} from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';
import { FILTER_COUNTER_OPTIONS, SORTING_TYPE } from '@/utils/constants';
import { getFilteredOptionsProducts } from '@/utils/getFilteredOptionProducts';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { GlobalCfeLoader } from '@/components/WidgetMaker/WidgetDnD/GlobalCfeLoader';
import { ProductListWrapper } from 'src/components/DesignSystem/Widgets2.0/ProductListWrapper';

export function FilteredAndSortItems() {
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  const router = useRouter();
  const dispatch = useDispatch();

  const [isFullItemsLoaded, setIsFullItemsLoaded] = useState(false);
  const [initialFilter, setInitialFilter] = useState([]);

  const pageNo = useRef(null);
  const isNext = useRef(null);
  const loader = useRef(null);
  const initialPlpCall = useRef(null);

  const { filteredItems, filterAppliedCounter, sortType, filteredOptions } = useSelector(
    (state: any) => ({
      filteredItems: state.catalogReducer.filteredItems,
      filterAppliedCounter: state.catalogReducer.filterAppliedCounter,
      sortType: state.catalogReducer.sortType,
      filteredOptions: state.catalogReducer.filteredOptions,
    })
  );

  const { categories, productTags, storeData } = useSSRSelector((state) => ({
    categories: state.catalogReducer?.categories,
    productTags: state.catalogReducer?.productTags,
    storeData: state.storeReducer.store,
  }));

  useEffect(() => {
    /** if it is plp page plpinitfiltercall will call api with page no 1 */
    if (
      router?.query?.cid !== undefined &&
      !sortType &&
      filterAppliedCounter <= 1 &&
      !(parseInt(router?.query?.cid as string) === -1)
    ) {
      pageNo.current = [0, 1];
    } else {
      pageNo.current = [0];
    }

    loader.current = false;
    isNext.current = true;
    dispatch(resetFilterItems());
  }, [filterAppliedCounter, sortType]);

  useEffect(() => {
    initialPlpCall.current = false;
    plpInitFilterCall();
  }, [router?.query?.cid, categories, productTags]);

  function plpInitFilterCall() {
    if (router?.query?.cid) {
      const selectedCategory = categories.find(
        (categoryItem) => categoryItem?.id == router?.query?.cid
      );
      if (selectedCategory) {
        const categoryFilterObject = {};
        const categorySubFilter = [];
        const subFilter = {};
        subFilter['id'] = selectedCategory?.id;
        subFilter['name'] = selectedCategory?.name;
        categorySubFilter.push(subFilter);
        categoryFilterObject['sub_filters'] = categorySubFilter;
        categoryFilterObject['name'] = 'Categories';
        categoryFilterObject['type'] = 2;
        dispatch(saveFilteredOptions([categoryFilterObject]));
        setInitialFilter([categoryFilterObject]);
        plpFilterCall(categoryFilterObject, SORTING_TYPE.CATEGORIES);
      }
      const selectedTag = productTags.find(
        (tagItem) => tagItem?.id == router?.query?.cid
      );
      if (selectedTag) {
        const tagFilterObject = {};
        const tagSubFilter = [];
        const subFilter = {};
        subFilter['id'] = selectedTag?.id;
        subFilter['name'] = selectedTag?.name;
        tagSubFilter.push(subFilter);
        tagFilterObject['sub_filters'] = tagSubFilter;
        tagFilterObject['name'] = 'Tags';
        tagFilterObject['type'] = 3;
        dispatch(saveFilteredOptions([tagFilterObject]));
        setInitialFilter([tagFilterObject]);
        plpFilterCall(tagFilterObject, SORTING_TYPE.CATEGORIES);
      }
    }
  }

  async function loadMoreItems() {
    const currentPageNo = pageNo.current[pageNo.current?.length - 1] + 1;
    if (!loader.current && isNext.current && !initialPlpCall.current) {
      loader.current = true;
      const { status, is_next_page } = await getFilteredOptionsProducts(
        getSelectedFilters(),
        currentPageNo
      );
      if (status) {
        pageNo.current.push(currentPageNo);
        isNext.current = is_next_page;
        if (!is_next_page) {
          setIsFullItemsLoaded(true);
        }
      }
      loader.current = false;
    }
  }

  function getSelectedFilters() {
    /** add previous filteroption of collection and category and PLP page */
    const tempFiltersArray =
      router?.query?.cid !== undefined || router?.query?.collectionId !== undefined
        ? [...initialFilter]
        : [];
    !!filteredOptions?.length &&
      filteredOptions.forEach((filterItem) => {
        const tempFilterObject = {};
        const tempSubFilterArray = [];
        !!filterItem.sub_filters?.length &&
          filterItem.sub_filters.forEach((subFilterItem) => {
            const tempSubFilters = {};
            if (subFilterItem?.is_selected) {
              tempSubFilters['id'] = subFilterItem?.id;
              tempSubFilters['name'] = subFilterItem?.name;
              tempSubFilters['colour_code'] = subFilterItem?.colour_code;
              tempSubFilterArray.push(tempSubFilters);
            }
          });
        if (tempSubFilterArray?.length) {
          tempFilterObject['name'] = filterItem?.name;
          tempFilterObject['type'] = filterItem?.type;
          tempFilterObject['sub_filters'] = tempSubFilterArray;
          tempFiltersArray.push(tempFilterObject);
        }
      });
    return tempFiltersArray;
  }

  function returnFilterItemsData() {
    return filteredItems;
  }

  function changeinitialPlpCallToFalse(status, isNextPage) {
    if (status) {
      initialPlpCall.current = false;
      isNext.current = isNextPage;
    }
  }

  function plpFilterCall(filterObject, sortType) {
    const payload = {
      store_id: storeData?.store_id,
      page_no: 1,
      sorting_type: sortType,
      filters: [filterObject],
    };
    initialPlpCall.current = true;
    dispatch(getFilteredItemsOptions(payload, changeinitialPlpCallToFalse));
    dispatch(filtersCounter(FILTER_COUNTER_OPTIONS.INC));
  }

  return (
    <div className="SortAndFilteredItemsMid6">
      <FlatList
        isLoaderVisible={false}
        isNext={true}
        onScrollEnd={() => loadMoreItems()}
        renderInsideFragment
        // add LoaderComponent for loading sections
        renderList={() => {
          return (
            <ProductListWrapper
              productList={returnFilterItemsData()}
              oldCardConfigs={{
                showButton: true,
                noTagsReadState: 'fromLength',
              }}
            />
          );
        }}
      />
      {loader.current && isNext.current && !isFullItemsLoaded && (
        <div className="flex justify-center mt4 relative">
          <GlobalCfeLoader
            type={globalStyle?.appLoader?.loaderId || 'ellipsis'}
            color={globalStyle?.appLoader?.color}
          />
          {/* <LoaderIcon style={{ transform: 'translateX(-50%)' }} /> */}
        </div>
      )}
    </div>
  );
}
