import React from 'react';

const CustomButton = ({className ,text, isDisable, onClickhandler}) => {
    return (
        <button 
            className={`catalog-${className} ${isDisable ? 'disable' : ''}`}
            disabled={isDisable}
            onClick={onClickhandler}>  
            {text}
        </button>
    );
};

export default CustomButton;