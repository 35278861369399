import React from 'react';
import Modal from './../Common/Modal';
import HaveSomeThingInMind from './../HaveSomethingInMind';
import { useSelector } from 'react-redux';
import router from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';

export default function CategoryModal(props) {
  const [themeColor, setThemeColor] = React.useState(null);
  const { categories, productTags } = useSelector((state) => state.catalogReducer);
  const storeData = useSelector((state) => state.storeReducer.store);

  React.useEffect(() => {
    if (storeData?.theme?.colors?.primary_color) {
      setThemeColor(
        storeData?.premium ? storeData?.theme?.colors?.primary_color : '#000000'
      );
    }
  }, [storeData]);

  const onCategoryClick = (category) => {
    props.toggleModal();
    if (isPLPRequiredSystem(storeData?.theme?.theme_class)) {
      router.push(
        getRoute(`${PATH.SHOP}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    } else {
      router.push(
        getRoute(`${PATH.PRODUCT}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    }
  };

  return (
    <Modal
      visible={props.isVisible}
      className="bottom-modal"
      animation={'slideUp'}
      onClose={props.toggleModal}
    >
      <div className="category-modal-wrap">
        <div>
          <span className="closeicon pointer" onClick={props.toggleModal}>
            <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
          </span>
        </div>
        <div className="category-container">
          <div className="category-box">
            <ul>
              {[...productTags, ...categories].map((item, idx) => (
                <>
                  {productTags.length > 0 && productTags.length === idx && (
                    <div className="product-tag-item-divider"></div>
                  )}
                  <li
                    className="product-tag-item pointer"
                    style={{
                      color: themeColor,
                      border: productTags.length - 1 === idx && 'none',
                    }}
                    key={`${item?.id}-tag-popup`}
                    onClick={() => onCategoryClick(item)}
                  >
                    {item?.name || 'Available Items'}
                  </li>
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {!storeData?.theme && (
        <div className="category-havesomthing">
          <HaveSomeThingInMind islink={true} />
        </div>
      )}
    </Modal>
  );
}
