import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveCart } from './../../redux/actions';
import CustomBtn from './../Common/CustomBtn';
import { useRouter } from 'next/router';

const HaveSomeThingInMind = ({ islink, isCartView, forWeb, icon }) => {
  const router = useRouter();
  const cart = useSelector(state => state.cartReducer);
  const storeInfo = useSelector(state => state.storeReducer.store);
  const dispatch = useDispatch();

  const handleCart = () => {
    const temp = [...cart.items];
    if (
      temp.filter(item => item.item_name !== '' && item.item_quantity !== '')
    ) {
      temp.push({
        item_id: Date.now(),
        item_name: '',
        quantity: '',
        item_type: 'list',
      });
      dispatch(saveCart({ items: temp }));
    }
  };

  const onClickHandler = () => {
    if (islink) {
      handleCart();
      router.push(`/${storeInfo.store_info.domain.split('-')[1]}/cart`);
    } else if (isCartView) {
      handleCart();
    }
    return;
  };

  const renderForWeb = () => {
    return (
      <div className="web-havesomethingInMind--wrapper">
        {renderForMobile(icon)}
        <div className="web-havesomethingInMind--btnwrap">
          <CustomBtn
            className="custom-btn"
            text="Create list"
            isDisable={false}
            onClickhandler={() => {
              handleCart();
            }}
          />
        </div>
      </div>
    );
  };

  const renderForMobile = icon => {
    return (
      <div className="havesomething-in-mind__box" onClick={onClickHandler}>
        <div className="havesomething-in-mind">
          <span>Have some specific thing in mind ?</span>
          <span>
            Make a list of items you need
            {islink && (
              <img
                loading="lazy"
                src="/assets/images/arrowf.png"
                className="havesomething-in-mind__arrow"
                alt="forward arrow icon"
              />
            )}
          </span>
        </div>
        {icon && (
          <div className="havesomething-in-mind__box-image">
            <img
              loading="lazy"
              src="/assets/images/perwithscope@2x.png"
              alt="Have something in mind icon"
            />
          </div>
        )}
      </div>
    );
  };

  return forWeb ? renderForWeb() : renderForMobile(true);
};

export default HaveSomeThingInMind;
