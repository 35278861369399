import React from 'react';
import { useSelector } from 'react-redux';
import { ProductListWrapper } from '../../Widgets2.0/ProductListWrapper';

const CategoryItems = ({ category }) => {
  const {
    catalogData: { catalog_items },
  } = useSelector((state: any) => ({
    catalogData: state.catalogReducer,
  }));

  const returnCategoryData = (categoryId) => {
    return catalog_items[categoryId];
  };

  const categoryData = returnCategoryData(category?.id);

  return (
    <ProductListWrapper
      productList={categoryData}
      oldCardConfigs={{
        showButton: true,
        noTags: category.hasOwnProperty('is_custom_tag'),
      }}
    />
  );
};

export default CategoryItems;
